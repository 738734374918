<template>
    <div class="p-d-flex p-flex-column p-jc-start minusMargin" style="height: calc(100% + 26px);">
        <div class="p-d-flex p-jc-between minusMargin2 listPage" style="height: calc(100% + 16px);">
            <div class="p-col-12 p-d-flex p-col-customPad p-flex-column p-px-0 p-py-0">
                <TreeTable
                    :value="filterItems"
                    :scrollable="true"
                    :lazy="true"
                    :paginator="true"
                    scroll-height="calc(100vh - 450px)"
                    class="p-datatable-customers p-datatable-striped organization-list"
                    :class="{ showFilters }"
                    :rows="pageSize"
                    :rowsPerPageOptions="rowsPerPageOptions"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate=""
                    :totalRecords="totalRecords"
                    :pageLinkSize="4"
                    selectionMode="single"
                    resizable-columns
                    :filters="filters"
                    filterMode="lenient"
                    @node-expand="rowExpandHandler"
                    @row-dblclick="rowDblClickHandler"
                    @page="onPage"
                    @sort="onSort"
                    :contentStyle='{ overflow: "visible" }'
                >
                    <template #header>
                        <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                            <div class="p-col-8 p-p-0 p-d-flex p-ai-center elementMargin">
                                <h5 class="p-d-inline p-mb-0 p-pt-0">Заполнение</h5>
                            </div>
                            <div class="p-col-5 p-p-0 p-d-flex p-ai-center table-active-period">
                                <Dropdown
                                    v-model="selectedOrganization"
                                    :disabled="!isRightSelectOrganization"
                                    option-value="id"
                                    placeholder="Организация"
                                    :options="organizationsList"
                                    option-label="attributes.publicName"
                                    data-key="id"
                                    class="customDropDown p-column-filter"
                                    filter
                                    @filter="debouncedOrganizationFilter"
                                    @change="selectedOrgFilter"
                                   />
                                <Dropdown
                                    v-model="selectedForm"
                                    placeholder="Форма"
                                    option-value="id"
                                    :options="formList"
                                    option-label="name"
                                    data-key="id"
                                    class="customDropDown p-column-filter"
                                    filter
                                    @change="clearData"
                                   /> <!--@change="selectedFilter"
                                /-->
                                <Dropdown
                                    v-model="selectedPeriod"
                                    :options="activePeriodList"
                                    optionLabel="label"
                                    placeholder="Активный период"
                                    class="p-column-filter"
                                    @click.stop
                                    @change="clearData"
                                    /><!-- @change="selectedFilter"
                                /-->
                                <Calendar v-show="selectedPeriod.value === 'range'"
                                          id="calendarStart"
                                          v-model="rangeStart"
                                          :showIcon="true"
                                          dateFormat="dd.mm.yy"
                                          :locale="rusCalendareLocale"
                                          :showOnFocus="false"
                                          @change="clearData"
                                        /><!--  @change="selectedFilter"
                                /-->
                                <Calendar v-show="selectedPeriod.value === 'range'"
                                          id="calendarEnd"
                                          v-model="rangeEnd"
                                          :showIcon="true"
                                          dateFormat="dd.mm.yy"
                                          :locale="rusCalendareLocale"
                                          :showOnFocus="false"
                                          @change="clearData"
                                         /><!-- @change="selectedFilter"
                                /-->
                                <div class="p-p-0 p-new-btn">
                                    <Button @click="selectedFilter" class="p-button">Применить фильтр</Button>
                                </div>
                            </div>
                            <!--div class="p-col-7 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                                <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                                    <span class="p-float-label">
                                        <InputText type="text"
                                                   v-model="filters.name"
                                                   placeholder="Поиск"
                                                   @keydown="selectedFilter"
                                        />
                                    </span>
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-search"></i>
                                    </span>
                                </div>
                            </div-->
                        </div>
                    </template>
                    <template #empty>
                        <span v-if="acceptFilter">
                            По выбранным параметрам раздачи не найдены.
                        </span>
                        <span v-else style="height: 400px !important; display: block; padding-top: 160px; text-align: center; font-size: 24px; line-height: 41px;">
                            Выберите Организацию, Форму и Период.<br>Нажмите кнопку "Применить фильтр".
                        </span>
                    </template>
                    <template #loading>Загрузка списка раздач. Пожалуйста, подождите...</template>
                    <Column
                        header="Организация"
                        header-class='entry-header-name table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-name table-body'
                        field="organization"
                        :expander="true"
                    >
                        <template #body="slotProps">
                            <span>{{ slotProps.node.data?.organization }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.filial"
                                :options="filialList"
                                :showToggleAll="true"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="attributes.shortName"
                                display="chip-count"
                                filter
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Тип"
                        header-class="entry-header-org table-header"
                        body-class="p-text-nowrap p-text-truncate entry-body-org table-body"
                        field="formType"
                    >
                        <template #body="slotProps">
                            <span>{{ slotProps.node.data?.formType }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.formTypes"
                                :options="formTypes"
                                :showToggleAll="false"
                                data-key="value"
                                option-value="value"
                                placeholder="Поиск"
                                option-label="label"
                                display="chip"
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Периодичность"
                        header-class="entry-header-org table-header"
                        body-class="p-text-nowrap p-text-truncate entry-body-org table-body"
                        field="periodicity"
                    >
                        <template #body="slotProps">
                            <span>{{ slotProps.node.data?.periodicity }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.periodicity"
                                :options="formPeriodsFilters"
                                :showToggleAll="true"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="attributes.name"
                                display="chip-count"
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Форма"
                        header-class="entry-header-org table-header"
                        body-class="p-text-nowrap p-text-truncate entry-body-org table-body"
                        field="name"
                        filterMatchMode="contains"
                    >
                        <template #body='slotProps'>
                            <span>{{ slotProps.node.data?.name }}</span>
                        </template>
                        <template #filter v-show='showFilters'>
                            <InputText
                                v-show='showFilters'
                                type="text"
                                v-model="filters.name"
                                class="p-column-filter p-my-2"
                                placeholder="Поиск"
                                @keydown="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header="Период"
                        header-class='entry-header-period table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-period table-body'
                        field="period.activeFrom"
                        :sortable="true"
                        filterMatchMode="contains"
                    >
                        <template #body="slotProps">
                            <span>{{ slotProps.node.data?.periodType }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.period"
                                :options="periodList"
                                :showToggleAll="true"
                                data-key="id"
                                option-value="id"
                                placeholder="Поиск"
                                option-label="attributes.name"
                                display="chip-count"
                                filter
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header=""
                        header-class="entry-header-empty table-header"
                        body-class="p-text-nowrap p-text-truncate entry-body-empty table-body"
                        field="empty">
                        <template #body="slotProps">
                            <span v-if="slotProps.node.data?.empty" class="pi pi-file"></span>
                        </template>
                    </Column>
                    <Column
                        header="Статус"
                        header-class='entry-header-status table-header'
                        body-class='p-text-nowrap p-text-truncate entry-body-status table-body'
                        field="status"
                    >
                        <template #body='slotProps'>
                            <span
                                :title="getStatus(slotProps).title"
                                :style="`background: ${getStatus(slotProps).bgColor}; color: ${getStatus(slotProps).textColor}; padding: 4px; border-radius: 4px;`"
                            >{{ getStatus(slotProps).label }}</span>
                        </template>
                        <template #filter v-show="showFilters">
                            <MultiSelect26
                                v-show="showFilters"
                                v-model="filters.status"
                                :options="entryStatuses"
                                :showToggleAll="false"
                                data-key="label"
                                option-value="value"
                                placeholder="Поиск"
                                option-label="label"
                                display="chip"
                                class="multiselect-custom"
                                @change="selectedFilter"
                            />
                        </template>
                    </Column>
                    <Column
                        header=""
                        header-class="p-text-center"
                        body-style="position: relative; width: 52px; text-align: center;"
                        header-style="width: 70px;"
                    >
                        <template #header>
                            <Button
                                icon="pi pi-filter"
                                :class="filterClasses"
                                class="p-button-rounded p-button-outlined filter-btn"
                                style="margin-right: 4px;"
                                @click="showFilters = !showFilters"
                            />
                            <Button
                                icon="pi pi-times p-button-icon"
                                :class="filterClasses"
                                class="p-button-rounded p-button-outlined filter-btn"
                                @click="clearFilters"
                            />
                        </template>
                        <template v-if='showThreePointsMenu(listMenuItemsComputed)' #body="slotProps">
                            <div
                                class="p-panel-header-icon p-link"
                                @click.stop="toggleRowMenu($event, slotProps)"
                                v-if="slotProps.node.menuActive"
                            >
                                <span class="pi pi-ellipsis-h"></span>
                            </div>
                            <Menu
                                class="redLastListElement"
                                :ref="`listMenu${slotProps.node.key}`"
                                :model="menuItems(listMenuItemsComputed)"
                                :popup="true"
                                :baseZIndex="10"
                            />
                        </template>
                    </Column>
                    <template #paginatorLeft>
                        <div class='p-col-6'></div>
                    </template>
                </TreeTable>
            </div>
        </div>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import {
    getDistributionListData,
    sendDistributionReview,
    sendUnloadingData,
} from '@/api/form/formsDistributions';
import { DISTRIBUTION_STATUSES } from '@/constants/distributions';
import { USER_PERMISSIONS_MAP, FORM_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';
import { getOrganizationId } from '@/api/authData';
import { jsonApiListParser } from '@/main/utils/common';
import { getOrganizations, getOrganizationById, getOrganizationAssignments } from '@/api/organization';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getFormGroupPermissions } from '@/api/form';
import { getPeriods } from '@/api/form/formsPeriods';
import { rusCalendareLocale } from '@/main/calendareLocales';
import { XSL_TEMPLATE_ID } from '@/constants/distributions';
import { FORM_STATUSES_NAMES } from "@/constants/forms";

const {
    formRead,
    formReadOwn,
    formVerificationRules,
    formWriteOwn,
    formWrite,
    formReview,
    formApprove
} = USER_PERMISSIONS_MAP

const {
    read,
    write,
    report
} = FORM_PERMISSIONS_MAP

const orgFilter = {
    '$or': [
        { 'status.activeUntil': { '$gte': new Date() } },
        { 'status.activeUntil': null },
    ]
}

const { ACTIVE, ARCHIVE, DRAFT } = FORM_STATUSES_NAMES;

export default {
    name: 'DataEntryList',
    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],

    activated() {
        if (this.dataLoaded) {
            this.$emit('loadingChange');
        }
    },
    data() {
        this.entryStatuses = DISTRIBUTION_STATUSES;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            formTypes: [
                { label: 'Мониторинг', value: 'monitoring' },
                { label: 'Форма', value: 'form' }
            ],
            currentTemplateId: null,
            filters: {},
            showFilters: false,
            dataLoaded: false,
            worksheets: [],
            activePeriodList: [
                { value: 'day', label: 'Текущий день' },
                { value: 'week', label: 'Текущая неделя' },
                { value: 'month', label: 'Текущий месяц' },
                { value: 'year', label: 'Текущий год' },
                { value: 'all', label: 'Все периоды' },
                { value: 'future', label: 'Будущие периоды' },
                { value: 'pastMonth', label: 'Прошлый месяц' },
                { value: 'pastYear', label: 'Прошлый год' },
                { value: 'range', label: 'Диапазон' },
            ],
            selectedPeriod: { value: 'week', label: 'Текущая неделя' },
            organizationsList: null,
            formList: null,
            filialList: null,
            periodList: null,
            selectedOrganization: null,
            selectedForm: null,
            isRightSelectOrganization: true,
            filterTimeout: null,
            loadingOrgs: false,
            distributionId: '',
            distributions: null,
            dataEntryList: [],
            acceptFilter: false,
            included: [],
            editRow: null,
            arrayLength: 5,
            new: [],
            types: [],
            currentPage: 1,
            sortField: null,
            totalRecords: null,
            entryStatuses: DISTRIBUTION_STATUSES,
            worksheetsStatus: [],
            showReview: false,
            showEdit: false,
            showView: false,
            showReport: false,
            formGroupPermissions: [],
            rangeStart: null,
            rangeEnd: null,
            formPeriodsFilters: [],
        }
    },
    computed: {
        ...mapGetters('auth', ['currentUser', 'accessibleItems', 'userPermissionsObject', 'formPermissionsObject']),
        ...mapGetters(['rowsPerPageOptions', 'dataEntryFilter']),

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        filterItems() {
            return this.dataEntryList;
        },
        showAllDistributions() {
            return [ formRead, formWrite, formReview, formApprove, formVerificationRules ].some(p => this.userPermissionsObject[p]);
        },
        listMenuItemsComputed() {
            return [
                {
                    label: 'Заполнить',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem();
                    },
                    visible: this.showEdit,
                    permissionAccess: [
                        formWriteOwn,
                        formWrite,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Просмотр',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem();
                    },
                    visible: this.showView,
                    permissionAccess: [
                        formWriteOwn,
                        formWrite,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Скачать XLS',
                    icon: 'pi icon custom-xls-file',
                    command: () => {
                        this.startUnloading(this.distributionId, XSL_TEMPLATE_ID);
                    },
                    visible: this.showReport && !this.nodeData.firstWorksheetId,
                    permissionAccess: [
                        formReadOwn,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Отправить на проверку',
                    icon: 'pi icon custom-status-change',
                    command: () => {
                        this.sendReview();
                    },
                    visible: this.showReview,
                    permissionAccess: [
                        formWriteOwn,
                        formVerificationRules
                    ]
                }
            ]
        }
    },
    methods: {
        ...mapMutations(['saveDataEntryFilter']),
        ...mapActions(['initDataEntryFilter']),
        menuItems(menu) {
            return this.accessibleItems(menu)
        },
        showThreePointsMenu(menu) {
            return this.menuItems(menu).length && !this.menuItems(menu).every(i => i.separator)
        },
        rowDblClickHandler(event) {
            const { node: { id, key, distributionId, data } } = event;
            this.distributionId = distributionId;
            this.nodeData = data;
            this.editRow = key;

            if (data.formId && this.formPermissionsObject[data.formId]
                && (this.formPermissionsObject[data.formId][read] || this.formPermissionsObject[data.formId][write])) {
                this.editItem();
            }

            if (data.firstWorksheetId && this.formGroupPermissions[id]
                && (this.formGroupPermissions[id]['read'] || this.formGroupPermissions[id]['write'])) {
                this.editItem();
            }

            if (data.formId && !Object.keys(this.formPermissionsObject).length) {
                this.editItem();
            }

            if (data.firstWorksheetId && !Object.keys(this.formPermissionsObject).length) {
                this.editItem();
            }
        },

        /** Загружаем группы для форм */
        async rowExpandHandler(node) {
            try {
                if (node.templateId) {
                    this.currentTemplateId = node.templateId;
                    let lazyNode = {...node};
                    let groups = [];

                    groups = this.agregateGroups(node.data.organizationType, node.data.periodType, node.distributionId, false, node.template);

                    let branches = [];

                    if (node.data.formId) {
                        const {list, included} = await getDistributionListData({
                            filter: {
                                'period.id': {'$eq': node.data.period.id},
                                'template.form.id': {'$eq': node.data.formId},
                                'organization.parentId': {'$eq': node.data.organizationId}
                            }
                        });
                        if (list?.length > 0) {
                            branches = this.aggregateBranches(list, included, node);
                            branches = branches.sort((a, b) => (a.data.organization > b.data.organization ? 1 : -1));
                        }
                    }

                    lazyNode.children = [...branches, ...groups];
                    this.dataEntryList = this.dataEntryList.map(n => {
                        if (n.key === node.key) {
                            n = lazyNode;
                        }
                        return n;
                    });
                }
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        aggregateBranches(list, included, node) {
            try {
                const organizations = jsonApiListParser(included.filter(i => i.type === 'organization'), included);
                const forms = jsonApiListParser(included.filter(i => i.type === 'form'), included);

                let branches = list.map((distribution) => {
                    let
                        template = node.data.template,
                        period = node.data.period,
                        form = this.getFormData(distribution, template, forms),
                        organization = organizations.find(
                            (organization) => organization.id === distribution.relationships?.organization.data.id
                        ),
                        currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000
                    ;


                    let groups = [];

                    groups = this.agregateGroups(node.data.organizationType, node.data.periodType, node.distributionId, true, node.template);

                    return {
                        template: template,
                        form: form,
                        organization: organization,
                        branch: true,
                        data: {
                            organization: organization.publicName,
                            subordination: organization.subordinateTo ? organization.subordinateTo.name : '',
                            location: organization.location ? organization.location.name : '',
                            district: organization.district ? organization.district.name : '',
                            periodType: period.attributes.name,
                            name: form.name,
                            empty: distribution.attributes.empty,
                            status: this.getStatusDistribution(distribution),
                        },
                        templateId: template.id,
                        children: groups,
                        key: distribution.id,
                        id: distribution.id,
                        distributionId: distribution.id,
                        menuActive: true,
                        period: period,
                        activeFrom: (new Date(period.attributes.activeFrom)).getTime() + currentTimeZoneOffset,
                        activeTo: (new Date(period.attributes.activeUntil)).getTime() + currentTimeZoneOffset,
                    };
                });
                return branches;
            } catch (error) {
                console.log(error);
            }
        },
        async toggleRowMenu(event, slotProps) {
            const {node: { id, key, distributionId, templateId, data, branch }} = slotProps;

            if (key !== this.editRow) {
                this.$refs[`listMenu${this.editRow}`] && this.$refs[`listMenu${this.editRow}`].hide(event);
            }

            this.distributionId = distributionId;
            this.currentTemplateId = templateId;
            this.nodeData = data;

            this.showReview = [ 'new', 'in_work', 'declined' ].includes(data.status) && !branch;
            if (!this.currentUser.userPermissions.find((permission) => ['formWrite', 'formApprove', 'formReview', 'formVerificationRules'].includes(permission.id))) {
                this.showEdit = [ 'new', 'in_work', 'declined' ].includes(data.status);
            } else {
                this.showEdit = true;
            }
            this.showReport = true;
            this.showView = !this.showEdit;
            if (data.firstWorksheetId) {
                this.showReport = false;
            }

            if (this.formPermissionsObject[data.formId]
                && !this.formPermissionsObject[data.formId][write]
                && !this.formPermissionsObject[data.formId][read]) {
                this.showEdit = false;
                this.showView = false;
                this.showReview = false;
            }
            if (this.formPermissionsObject[data.formId]
                && !this.formPermissionsObject[data.formId][report]) {
                this.showReport = false;
            }
            if (this.formPermissionsObject[data.formId]
                && !this.formPermissionsObject[data.formId][write]
                && this.formPermissionsObject[data.formId][read]) {
                this.showEdit = false;
                this.showView = true;
                this.showReview = false;
            }
            if (Object.keys(this.formPermissionsObject).length > 0
                && !this.formPermissionsObject[data.formId]) {
                this.showEdit = false;
                this.showView = false;
                this.showReview = false;
                this.showReport = false;
            }

            if (data.firstWorksheetId && this.formGroupPermissions[id]
                && !this.formGroupPermissions[id]['read'] && !this.formGroupPermissions[id]['write']) {
                this.showEdit = false;
                this.showView = false;
                this.showReview = false;
            }

            if (data.firstWorksheetId && this.formGroupPermissions[id]
                && this.formGroupPermissions[id]['write']) {
                this.showEdit = [ 'new', 'in_work', 'declined' ].includes(data.status);
                this.showView = !this.showEdit;
                this.showReview = [ 'new', 'in_work', 'declined' ].includes(data.status) && !branch;
            }

            if (data.firstWorksheetId && this.formGroupPermissions[id]
                && !this.formGroupPermissions[id]['write']
                && this.formGroupPermissions[id]['read']) {
                this.showEdit = false;
                this.showView = true;
                this.showReview = false;
            }

            if (data.firstWorksheetId && Object.keys(this.formGroupPermissions).length > 0
                && !this.formGroupPermissions[id]) {
                this.showEdit = false;
                this.showView = false;
                this.showReview = false;
            }

            this.editRow = key;
            const menuEl = this.$refs[`listMenu${key}`];

            // Open new popup
            menuEl.toggle(event);
            await this.$nextTick();
            const {y} = event;
            const {$el} = menuEl;

            if ($el.style) {
                $el.style.top = `${y}px`;
            }
        },
        async sendReview() {
            try {
                this.$root.reachGoal('send-review');
                this.$emit('loadingChange', true);
                let data = {};
                if (this.nodeData.worksheets) {
                    let worksheets = {
                        data: []
                    };

                    this.nodeData.worksheets.forEach((worksheet) => {

                        if (worksheet?.status !== 'accepted') {
                            worksheets['data'].push({
                                type: 'form-template-worksheet',
                                id: worksheet.id
                            });
                        }
                    });

                    data = {
                        data: {
                            worksheets: worksheets
                        }
                    };
                }
                await sendDistributionReview(this.distributionId, data);

                this.$toast.add({
                    severity: 'success',
                    summary: this.nodeData.worksheets ? 'Группа отправлена на проверку' : 'Форма отправлена на проверку',
                    life: '3200',
                });

                this.updateDistributionList(this.selectedOrganization, this.selectedForm, this.selectedPeriod.value, this.filters);
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange');
            }
        },

        onPage({ page, rows }) {
            this.pageSize = rows;
            this.currentPage = page + 1;
            this.debouncedFilter();
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        async getData(/*organizationId*/) {
            this.$emit('loadingChange', true);

            try {
                const today = new Date();
                let filter = {
                    '$and': [
                        orgFilter,
                        {
                            'status.activeFrom': { $lte: `${ today.toISOString().substring(0, 10) }T00:00:00Z` }
                        }
                    ]
                };
                await Promise.all([
                    getOrganizations({
                        page: 1,
                        pageSize: 20,
                        filter,
                        sortParametr: 'name.fullName'
                    }).then(async ({organizations}) => {
                        this.organizationsList = organizations;
                        this.organization = this.organizationsList.find(organization => organization.id === this.selectedOrganization);
                        if (!this.organization) {
                            const { data: orgData } = await getOrganizationById(this.selectedOrganization);
                            this.organization = orgData;
                            this.organizationsList.push(this.organization);
                        }
                    }),
                    this.updateFormList()
                ]);
                if (this.dataEntryFilter) {
                    this.currentPage = 1;
                    this.pageSize = DEFAULT_PAGE_SIZE;
                    if (this.filters) {
                        this.showFilters = true;
                    }
                   // this.updateDistributionList(this.selectedOrganization, this.selectedForm, this.selectedPeriod.value, this.filters);
                }/* else {
                    let firstDayOfWeek = today.getDate() - today.getDay() + 1;
                    let lastDayOfWeek = today.getDate() - today.getDay() + 7;

                    await Promise.all([
                        getDistributionListData({
                            pageNumber: this.currentPage,
                            pageSize: this.pageSize,
                            filter: {
                                '$and': [
                                    {
                                        '$or': [
                                            { 'organization.id': { '$eq': organizationId } },
                                        ]
                                    },
                                    {
                                        'period.activeFrom': {'$gte': new Date(today.setDate(firstDayOfWeek)).toISOString().substring(0, 10)},
                                        'period.activeUntil': {'$lte': new Date(today.setDate(lastDayOfWeek)).toISOString().substring(0, 10)}
                                    }
                                ]
                            }
                        }).then(({ list, included, meta }) => {
                            this.included = included;
                            this.distributions = list;
                            this.totalRecords = meta.pagination.total;
                            this.buildDistributionTree();
                        }),
                        getPeriods({ filter: {
                            'activeFrom': { '$lte': `${today.toISOString().substring(0, 10)}T00:00:00Z` },
                            'activeUntil': { '$gte': `${today.toISOString().substring(0, 10)}T23:59:59Z` }
                        } }).then(({ data: { data: periods, included: intervals } }) => {
                            this.periodList = periods;
                            this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');
                        }),
                        getOrganizations({ filter: {
                                $or: [
                                    {
                                        id: { $eq: this.organization.id },
                                    },
                                    {
                                        parentId: { $eq: this.organization.id }
                                    }
                                ]
                            }, relationShip: true }).then(({ organizations: filials }) => {
                            this.filialList = filials;
                        })
                    ]);
                }*/
            } catch (error) {
                this.$requestError(error.message);
            }
            this.$emit('loadingChange');
        },
        async buildDistributionTree() {
            const forms = jsonApiListParser(this.included.filter(i => i.type === 'form'), this.included);
            const organizations = jsonApiListParser(this.included.filter((item) => item.type === 'organization'), this.included);
            let distributionData = jsonApiListParser(this.included.filter((item) => item.type === 'form-distribution-worksheet'), this.included);
            this.dataEntryList = this.distributions.map((distribution) => {
                let
                    template = this.getTemplateData(distribution),
                    period = this.getPeriod(distribution),
                    form = this.getFormData(distribution, template, forms),
                    groupExists = this.getGroupData(distribution, template),
                    organization = organizations.find((findOrganization) => findOrganization.id === distribution.relationships.organization.data.id),
                    currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000
                ;


                let menuActive = true;
                if (Object.keys(this.formPermissionsObject).length > 0 && form.id &&
                    (!this.formPermissionsObject[form.id]
                        || (this.formPermissionsObject[form.id]
                            && !this.formPermissionsObject[form.id][write]
                            && !this.formPermissionsObject[form.id][read]
                            && !this.formPermissionsObject[form.id][report]))) {
                    menuActive = false;
                }

                return {
                    template: template,
                    form: form,
                    branch: Object.keys(organization.parent).length > 0,
                    data: {
                        organizationId: organization.id,
                        period: period,
                        template: template,
                        formId: form.id,
                        name: form.name,
                        formType: this.formTypes.find(item => item.value === form.type).label,
                        periodicity: form.distributionInterval.name,
                        organization: organization.shortName,
                        periodType: period.attributes.name,
                        status: this.getStatusDistribution(distribution),
                        worksheets: template.relationships.worksheets.data.map((worksheet) => {
                            let statusData = distributionData.find((item) => item.worksheet.id === worksheet.id);
                            worksheet.status = statusData.status;
                            return worksheet;
                        }),
                        empty: distribution.attributes.empty,
                    },
                    templateId: template.id,
                    children: Object.keys(organization.children).length > 0 || groupExists ? [{}] : [],
                    key: distribution.id,
                    id: distribution.id,
                    distributionId: distribution.id,
                    menuActive,
                    activeFrom: (new Date(period.attributes.activeFrom)).getTime() + currentTimeZoneOffset,
                    activeTo: (new Date(period.attributes.activeUntil)).getTime() + currentTimeZoneOffset,
                }
            });

           // await this.expandAll();
        },
       /* async expandAll() {
            for (let node of this.dataEntryList) {
                await this.rowExpandHandler(node);
            }
        },*/
        getPeriod(distribution) {
            let periodData = distribution.relationships.period.data;
            if (periodData != null) {
                return this.included.find((period) => period.id === periodData.id);
            }
        },
        getTemplateData(distribution) {
            let templateData = distribution.relationships.template.data;
            if (templateData != null) {
                return this.included.find((template) => template.id === templateData.id);
            }
        },
        getGroupData(distribution, template) {
            if (template != null) {
                let groupExists = false;
                template.relationships.worksheets.data.forEach((worksheet) => {
                    let worksheetData = this.included.find((item) => item.id === worksheet.id);
                    let groupId = worksheetData.relationships.group?.data?.id;
                    if (groupId) {
                        groupExists = true;
                    }
                });
                return groupExists;
            }
        },
        getFormData(distribution, template, forms) {
            if (template != null) {
                return forms.find((findForm) => findForm.id === template.relationships.form.data.id);
            }
        },
        getStatusDistribution(distribution) {
            return (distribution.attributes.blocked) ? 'blocked' : distribution.attributes.status;
        },
        getStatus(slotProps) {
            const status = this.entryStatuses.find(item => item.value === slotProps.node.data?.status)
            return status || {}
        },
        editItem() {
            if (this.nodeData.firstWorksheetId) {
                this.$router.push(`/dataEntry/${ this.distributionId }/${ this.nodeData.firstWorksheetId }/`);
            } else {
                this.$router.push(`/dataEntry/${ this.distributionId }/`);
            }
        },

        agregateGroups(organizationType, periodType, distributionId, branch, template) {
            let distributionInfo = this.distributions.find((item) => item.id === distributionId);
            let worksheetStatuses = [];
            distributionInfo?.relationships.worksheets.data.forEach((worksheet) => {
                let worksheetStatus = this.included.find((item) => item.id === worksheet.id);
                worksheetStatuses[worksheetStatus.relationships.worksheet.data.id] = worksheetStatus;
            });

            let allWorksheets = [];
            let groups = [];

            template?.relationships.worksheets.data.forEach((worksheet) => {
                let worksheetData = this.included.find((item) => item.id === worksheet.id);
                let groupId = worksheetData.relationships.group?.data?.id;
                worksheetData.status = worksheetStatuses[worksheet.id];

                if (groupId) {
                    if (!allWorksheets[groupId]) {
                        allWorksheets[groupId] = [];
                        let group = this.included.find(group => group.id === groupId);
                        groups.push(group);
                    }

                    allWorksheets[groupId].push(worksheetData);
                } else {
                    if (!allWorksheets['noGroup']) {
                        allWorksheets['noGroup'] = [];
                    }

                    allWorksheets['noGroup'].push(worksheetData);
                }
            });

            let allGroups = [];
            groups?.forEach((group) => {
                let menuActive = true;

                if (Object.keys(this.formGroupPermissions).length > 0 && group.id &&
                    (!this.formGroupPermissions[group.id]
                        || (this.formGroupPermissions[group.id]
                            && !this.formGroupPermissions[group.id]['write']
                            && !this.formGroupPermissions[group.id]['read']))) {
                    menuActive = false;
                }
                if (allWorksheets[group.id]) {
                    let emptyValue = true;
                    allWorksheets[group.id].forEach((worksheet) => {
                        if (worksheet.status?.attributes.empty) {
                            emptyValue = false;
                            return;
                        }
                    })
                    allGroups.push({
                        ...group.attributes,
                        key: `${ distributionId }-${ group.id }`,
                        id: group.id,
                        branch: branch,
                        data: {
                            status: '',
                            name: group.attributes.name,
                            organizationType: organizationType,
                            periodType: periodType,
                            worksheets: allWorksheets[group.id],
                            firstWorksheetId: allWorksheets[group.id].shift().id,
                            empty: emptyValue
                        },
                        statuses: allWorksheets[group.id].map((worksheet) => {
                            let status = 'new',
                                statusData = worksheet.status;

                            if (statusData) {
                                status = statusData.attributes.status;
                            }
                            return status;
                        }),
                        distributionId: distributionId,
                        menuActive
                    });
                }
            });

            let statusIndexes = DISTRIBUTION_STATUSES.map((el) => {
                return el.value;
            });

            allGroups.map((group) => {
                let statusIndex = null;

                group.statuses?.map((status) => {
                    if (!status) {
                        return;
                    }

                    let findIndex = statusIndexes.indexOf(status);

                    if (
                        statusIndex === null ||
                        findIndex < statusIndex
                    ) {
                        statusIndex = findIndex;
                    }
                });

                group.data.status = statusIndexes[statusIndex];

                return group;
            })

            return allGroups.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        },

        debouncedOrganizationFilter({ value }) {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.updateOrganizationList(value);
            }, 1000);
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.updateDistributionList(this.selectedOrganization, this.selectedForm, this.selectedPeriod.value, this.filters);
            }, 1000);
        },

        async updateOrganizationList(val) {
            if (this.loadingOrgs) {
                return;
            }
            this.$emit('loadingChange', !(val && val.length < 3));

            if (val?.length > 0) {
                try {
                    this.loadingOrgs = true;
                    const today = new Date();
                    let filter = {
                        '$and': [
                            orgFilter,
                            {
                                'name.shortName': { '$ilike': val },
                                'status.activeFrom': { $lte: `${ today.toISOString().substring(0, 10) }T00:00:00Z` }
                            }
                        ]
                    };

                    const { organizations } = await getOrganizations({
                        page: 1,
                        pageSize: 20,
                        filter,
                        sortParametr: 'name.fullName'
                    })
                    this.organizationsList = organizations;
                    this.organization = this.organizationsList.find(organization => organization.id === this.selectedOrganization);
                    if (!this.organization) {
                        const { data: orgData } = await getOrganizationById(this.selectedOrganization);
                        this.organization = orgData;
                        this.organizationsList.push(this.organization);
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    this.$emit('loadingChange')
                    this.loadingOrgs = false
                }
                return;
            }

            const today = new Date();
            let filter = {
                '$and': [
                    orgFilter,
                    {
                        'status.activeFrom': { $lte: `${ today.toISOString().substring(0, 10) }T00:00:00Z` }
                    }
                ]
            };
            const { organizations } = await getOrganizations({
                page: 1,
                pageSize: 20,
                filter,
                sortParametr: 'name.fullName'
            });
            this.organizationsList = organizations;
            if (this.selectedOrganization) {
                this.selectedOrganization = getOrganizationId();
            }
            this.organization = this.organizationsList.find(organization => organization.id === this.selectedOrganization);
            if (!this.organization) {
                const {data: orgData} = getOrganizationById(this.selectedOrganization);
                this.organization = orgData;
                this.organizationsList.push(this.organization);
            }
            await this.updateFormList();

            this.$emit('loadingChange');
        },

        async selectedOrgFilter() {
            this.acceptFilter = false;
            this.dataEntryList = null;
            await this.updateFormList();
        },

        clearData() {
            this.acceptFilter = false;
            this.dataEntryList = null;
        },

        async updateFormList() {
            const { included: incOrgAssignments } = await getOrganizationAssignments({
                organizationId: this.selectedOrganization,
                include: 'form,form.distributionInterval,organization'
            });

            let forms = (incOrgAssignments || []).filter(i => i.type === 'form');
            this.formList = jsonApiListParser(forms);
            this.formList = this.formList.map((form) => {
                form.status = form.archive ? ARCHIVE : DRAFT;
                if (form.active) form.status = ACTIVE;
                return form;
            });
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        clearFilters() {
            this.filters = {};
            this.selectedOrganization = getOrganizationId();
            this.selectedForm = null;
            this.updateOrganizationList();
            this.selectedPeriod = { value: 'week', label: 'Текущая неделя' };
            this.showFilters = false;
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.acceptFilter = false;
            this.dataEntryList = [];
            //this.updateDistributionList(this.selectedOrganization, null, this.selectedPeriod.value, this.filters);
        },
        async updateDistributionList(organizationId, formId, period, filters = []) {
            if (!organizationId || !formId) {
                this.acceptFilter = false;
                return;
            }


            let filter = {
                $or: [
                    {
                        id: { $eq: organizationId },
                    },
                    {
                        parentId: { $eq: organizationId }
                    }
                ]
            };

            const { organizations: filials } = await getOrganizations({ filter, relationShip: true });
            this.filialList = filials;

            if (period === 'range' && (!this.rangeStart || !this.rangeEnd)) {
                return;
            }

            this.$emit('loadingChange', true);

            try {
                const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;

                await this.saveDataEntryFilter({
                    organization: this.selectedOrganization,
                    form: this.selectedForm,
                    period: this.selectedPeriod,
                    rangeStart: this.rangeStart ? new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) : null,
                    rangeEnd: this.rangeEnd ? new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) : null,
                    filters: filters
                });

                let filter = {};
                const today = new Date();
                const currentYear = today.getFullYear();
                const currentMonth = today.getMonth() + 1;

                if (period === 'day') {
                    filter = {
                        'period.activeFrom': { '$eq': `${today.toISOString().substring(0, 10)}T00:00:00Z` },
                        'period.activeUntil': { '$eq': `${today.toISOString().substring(0, 10)}T23:59:59Z` }
                    };

                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': { '$eq': `${today.toISOString().substring(0, 10)}T00:00:00Z` },
                        'activeUntil': { '$eq': `${today.toISOString().substring(0, 10)}T23:59:59Z` }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');
                }

                if (period === 'pastMonth') {
                    let lastMonthDate = new Date();
                    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1, 1);
                    filter = {
                        'period.activeFrom': {
                            '$gte': `${new Date(lastMonthDate).toISOString().substring(0, 10)}T00:00:00Z`
                        },
                        'period.activeUntil': {
                            '$lte': `${new Date(currentYear, currentMonth - 1, 1).toISOString().substring(0, 10)}T23:59:59Z`
                        }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': { '$gte': `${new Date(lastMonthDate).toISOString().substring(0, 10)}T00:00:00Z` },
                        'activeUntil': { '$lte': `${new Date(currentYear, currentMonth - 1, 1).toISOString().substring(0, 10)}T23:59:59Z` }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');
                }

                if (period === 'pastYear') {
                    filter = {
                        'period.activeFrom': { '$gte': `${new Date(currentYear - 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'period.activeUntil': { '$lte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': { '$gte': `${new Date(currentYear - 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'activeUntil': { '$lte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');
                }

                if (period === 'future') {
                    filter = {
                        'period.activeFrom': { '$gt': today.toISOString().substring(0, 10) }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': { '$gt': today.toISOString().substring(0, 10) }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval')
                }

                if (period === 'week') {
                    let firstDayOfWeek = today.getDate() - today.getDay() + 1;
                    let lastDayOfWeek = today.getDate() - today.getDay() + 7;

                    filter = {
                        'period.activeUntil': {
                            '$gte': `${new Date(today.setDate(firstDayOfWeek)).toISOString().substring(0, 10)}T00:00:00Z`,
                            '$lte': `${new Date(today.setDate(lastDayOfWeek)).toISOString().substring(0, 10)}T23:59:59Z`
                        }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeUntil': {
                            '$gte': `${new Date(today.setDate(firstDayOfWeek)).toISOString().substring(0, 10)}T00:00:00Z`,
                            '$lte': `${new Date(today.setDate(lastDayOfWeek)).toISOString().substring(0, 10)}T23:59:59Z`
                        }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals?.filter(({type}) => type === 'form-distribution-interval');

                }

                if (period === 'month') {
                    today.setDate(1);

                    filter = {
                        'period.activeFrom': {
                            '$gte': today.toISOString().substring(0, 10)
                        },
                        'period.activeUntil': {
                            '$lte': new Date(currentYear, currentMonth, 31).toISOString().substring(0, 10)
                        }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': {
                            '$gte': today.toISOString().substring(0, 10)
                        },
                        'activeUntil': {
                            '$lte': new Date(currentYear, currentMonth, 31).toISOString().substring(0, 10)
                        }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');

                }

                if (period === 'year') {
                    filter = {
                        'period.activeFrom': { '$gte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` },
                        'period.activeUntil': { '$lte': `${new Date(currentYear + 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z` }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': {
                            '$gte': `${new Date(currentYear, 0, 1).toISOString().substring(0, 10)}T23:59:59Z`
                        },
                        'activeUntil': {
                            '$lte': `${new Date(currentYear + 1, 0, 1).toISOString().substring(0, 10)}T23:59:59Z`
                        }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');

                }

                if (period === 'range' && this.rangeStart && this.rangeEnd) {
                    filter = {
                        'period.activeFrom': {
                            '$gte': `${ new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z`
                        },
                        'period.activeUntil': {
                            '$lte': `${ new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T23:59:59Z`
                        }
                    };
                    const { data: { data: periods, included: intervals } } = await getPeriods({ filter: {
                        'activeFrom': {
                            '$gte': `${ new Date(this.rangeStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z`
                        },
                        'activeUntil': {
                            '$lte': `${ new Date(this.rangeEnd.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T23:59:59Z`
                        }
                    } });
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals.filter(({type}) => type === 'form-distribution-interval');

                }

                if (formId && formId.length > 0) {
                    filter['template.form.id'] = { '$eq': formId };
                }

                if (filters.name && filters.name.length > 0) {
                    filter['template.form.name'] = { '$ilike': filters.name };
                }

                if (filters.formTypes && filters.formTypes.length > 0) {
                    filter['template.form.type'] = { '$in': filters.formTypes };
                }

                if (filters.periodicity && filters.periodicity.length > 0) {
                    if (filters.periodicity.length > 1) {
                        filter['period.interval.id'] = {'$in': filters.periodicity};
                    } else {
                        filter['period.interval.id'] = {'$eq': filters.periodicity[0]};
                    }
                }

                if (filters.status && filters.status.length) {
                    filter['status'] = { '$in': filters.status };
                }

                if (filters.period && filters.period.length > 0) {
                    if (filters.period.length > 1) {
                        filter['period.id'] = { '$in': filters.period };
                    } else {
                        filter['period.id'] = { '$eq': filters.period[0] };
                    }
                }

                let organizationFilter = [
                    { 'organization.id': { '$eq': organizationId } },
                ];

                if (filters.filial && filters.filial.length > 0) {
                    if (filters.filial.length > 1) {
                        organizationFilter = [
                            { 'organization.id': { '$in': filters.filial } }
                        ];
                    } else {
                        organizationFilter = [
                            { 'organization.id': { '$eq': filters.filial[0] } }
                        ];
                    }
                }

                if (period === 'all') {
                    const { data: { data: periods, included: intervals } } = await getPeriods({});
                    this.periodList = periods;
                    this.formPeriodsFilters = intervals?.filter(({type}) => type === 'form-distribution-interval');

                }
                if (period === 'all' && Object.keys(filter).length === 0) {
                    filter = {
                        '$or': organizationFilter
                    };
                } else {
                    filter = {
                        '$and': [
                            {
                                '$or': organizationFilter
                            },
                            filter
                        ]
                    };
                }
                this.acceptFilter = true;
                const { list, included, meta } = await getDistributionListData({
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    filter: filter,
                    sort: this.sortField
                });
                this.included = included;
                this.distributions = list;
                this.totalRecords = meta.pagination.total;

                await this.getGroupPermissions();
                this.buildDistributionTree();

                this.$emit('loadingChange');
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange');
            }
        },

        async getGroupPermissions() {
            let forms = this.included.filter((item) => item.type === 'form');
            let formIds = forms.map((form) => { return `formId[]=${ form.id }` }).join('&');
            const {
                data: permissionData,
                included: permissionIncluded
            } = await getFormGroupPermissions(this.currentUser.id, formIds);

            if (permissionData) {
                let formGroupPermissions = jsonApiListParser(permissionData, permissionIncluded);
                formGroupPermissions.forEach(({ group, operation }) => {
                    if (group?.id) {
                        if (!this.formGroupPermissions[group.id]) {
                            this.formGroupPermissions[group.id] = {
                                'read': false,
                                'write': false,
                                'approve': false,
                                'distribute': false,
                                'report': false,
                                'review': false,
                            };
                        }
                        this.formGroupPermissions[group.id][operation] = true;
                    }
                });
            }
        },

        async startUnloading(formDistributionId, unloadingFormat) {
            try {
                this.$root.reachGoal('upload');
                this.$emit('loadingChange', true);
                const result = await sendUnloadingData(formDistributionId, unloadingFormat);

                if (result.status === 204)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на генерацию отчета поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },
    },
    async created() {
        this.selectedOrganization = getOrganizationId();

        /** Фильтр по организации доступен с определёнными правами */
        if (this.currentUser) {
            if (!this.currentUser.userPermissions.find((permission) =>
                ['organizationReadAll', 'formWrite', 'formRead', 'formReview'].includes(permission.id))) {
                this.isRightSelectOrganization = false;
            }
        }

        /** При открытии страницы брать настройки фильтра из store */
        await this.initDataEntryFilter();
        if (this.dataEntryFilter) {
            this.selectedOrganization = this.dataEntryFilter.organization;
            this.selectedForm = this.dataEntryFilter.form;
            this.selectedPeriod = this.dataEntryFilter.period;
            this.rangeStart = new Date(this.dataEntryFilter.rangeStart);
            this.rangeEnd = new Date(this.dataEntryFilter.rangeEnd);
            this.filters = this.dataEntryFilter.filters;
        }

        if (this.selectedOrganization) {
            await this.getData(/*this.selectedOrganization*/);
            this.dataLoaded = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.layout-content-wrapper .page-container {
    padding: 16px 16px 0 !important;
}
.elementMargin {
    margin-top: 20px;
}
.minusMargin {
    margin: -20px -16px;
    background-color: #eaeaea;
}
.minusMargin2 {
    background-color: #eaeaea;
}
.card {
    padding: 0;
    min-height: 600px;
    flex: 1 0 auto;
    max-height: 100%;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
}
.p-col-custom {
    width: calc(100% - 273px);
}
.p-col-customPad {
    background-color: #fff;
    padding: 0 16px !important;
    border-radius: 3px;
}
.p-datatable-customers {
    ::v-deep {
        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
                .table-active-period{
                    margin-top: 12px;
                    .p-column-filter {
                        margin-right: 16px;
                    }
                }
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset !important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset !important;
                            overflow-x: hidden;
                            &:first-child {
                                padding-left: 49px;
                            }
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px !important;
                    &:not(.p-treetable-emptymessage):hover {
                        background: #a5dca8 !important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea !important;
                        &:hover {
                            background: #a5dca8 !important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }
        .entry {
            &-header {
                &-org {
                    width: calc(10vw + 16.2px);
                }
                &-period {
                    width: calc(12vw + 16.2px);
                }
                &-status {
                    width: calc(8vw + 16.2px);
                }
                &-empty {
                    width: 20px;
                }
            }
        }
    }
}
::v-deep .customDropDown {
    width: 225px;
    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        width: 450px !important;
        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
::v-deep span.p-menuitem-icon {
    min-width: 24px;
}
::v-deep {
    label.custom-radio input {
        display: none;
    }
    label.custom-radio input+div {
        content: "\a";
        background: #ffffff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: black;
        border: 2px solid #EAEAEA;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radio-icon {
        width: 12px;
        height: 12px;
        transition-duration: 0.2s;
        background-color: #ffffff;
        border-radius: 50%;
    }
    label.custom-radio input:checked+div {
        background: #388E3C;
        border-color: #2d7230;
        color: #ffffff;
    }
    label.custom-radio.decline input:checked+div {
        background: #FF6767;
        border-color: #FF6767;
        color: #ffffff;
    }
    .p-inputtextarea {
        margin: 4px 0;
    }
}
::v-deep .p-calendar .p-inputtext {
    width: 100px;
}
::v-deep .p-calendar-w-btn .p-datepicker-trigger {
    margin-right: 16px;
}
::v-deep .p-datepicker {
    width: 300px;
    .p-timepicker {
        height: 0 !important;
        div {
            display: none;
        }
    }
}
::v-deep .multiselect-custom {
    min-width: 98%;
    max-width: 98%;

    .p-multiselect-label {
        color: #8C8C8C;
        font-size: 13px;
    }

    .p-multiselect-token {
        align-items: center;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        .p-multiselect-token-label {
            margin-right: 0.25rem;
            white-space: normal;
        }
    }
    .p-multiselect-trigger {
        width: 1.3rem;
    }
    /*.p-multiselect-item {
        width: 220px;
    }*/
}
::v-deep .p-multiselect-token-label {
    overflow: hidden;
    text-overflow: ellipsis;
}
.p-multiselect-token-icon {
    cursor: pointer;
}
::v-deep .p-multiselect {
    .p-multiselect-label {
        cursor: pointer;
        overflow-y: auto;
        text-overflow: ellipsis;
        display: grid;
        max-height: initial;
        padding: 5px 6px;
        grid-gap: 4px;
        grid-template-columns: repeat(1, 1fr);
       // max-width: 210px;
    }
    .p-multiselect-label-container {
        min-height: 32px;
        font-size: 13px;
    }
}
</style>
